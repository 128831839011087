<div class="flex items-center justify-center w-full relative" (drop)="dropHandler($event);"
     (dragover)="dragOverHandler($event);" (dragenter)="enableDragged($event)" (dragleave)="disableDragged($event)"
     (click)="openFileSystem()">
  <label
    class="flex-col-center w-full border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-white h-40 2xl:h-56"
    [class]="{
         'border-royal-blue': isDragged,
          'hover:bg-gray-100': !isLoading
         }">
    <div class="flex-col-center py-6 px-6 w-full h-full">
      @if (selectedFiles.length && !isLoading) {
        @if (!multiple) {
          <span class="font-bold">{{ selectedFiles[0].name }}</span>
          <span>{{ getMoSizeIfKoIsTooBig(selectedFiles[0].size) }}</span>
        } @else {
          <div *ngIf="isSplitting" class="flex-col-center">
            <span class="text-xs text-gray-500">{{ 'upload.splitting' | transloco }}</span>
            <i class="fa-solid fa-circle-notch animate-spin"></i>
          </div>
          <div *ngIf="!isSplitting" class="flex-c gap-[0.2rem] overflow-y-scroll w-full h-full">
            @for (item of selectedFiles; track item.name) {
              <div class="flex flex-row w-full">
                <div class="flex flex-row justify-between w-full ml-3">
                  <span class="font-bold">{{ item.name }}</span>
                  <span>{{ getMoSizeIfKoIsTooBig(item.size) }}</span>
                </div>
              </div>
            }
          </div>
        }
      } @else if (selectedFiles.length && isLoading) {
        <div class="flex-col-center">
          <stiilt-spinner [indeterminate]="false" [enableText]="true" [progressValue]="combinedPercentage | async"></stiilt-spinner>
          <span class="text-xs text-gray-500 pt-3">{{ 'upload.uploading' | transloco }}</span>
        </div>
      } @else {
        <svg class="w-10 h-10 mb-3 text-gray-400" fill="none" stroke="#0059f7" viewBox="0 0 24 24"
             xmlns="http://www.w3.org/2000/svg">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path>
        </svg>
        <p class="mb-2 text-sm text-gray-500"><span class="font-semibold">{{
            'upload.click_for_upload' | transloco
          }}</span> {{ 'upload.or_drop_for_upload' | transloco }}
        </p>
        <p class="text-xs text-gray-500">{{ hintText }}</p>
      }
    </div>
  </label>
</div>
<input #fileInput type="file" style="display: none" (cancel)="handleCancel($event)" (change)="handleFileInput($event)"
       [multiple]="multiple" [accept]="getAcceptTypes()"/>
