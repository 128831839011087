import { Component, Input, OnInit } from '@angular/core';
import { TranslocoPipe, TranslocoService } from '@jsverse/transloco';

export enum AlertType {
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
  DANGER = 'danger',
}

@Component({
  selector: 'stiilt-alert',
  standalone: true,
  imports: [TranslocoPipe],
  templateUrl: './alert.component.html',
})
export class AlertComponent {
  protected readonly AlertType = AlertType;

  @Input() public type: AlertType = AlertType.INFO;
  @Input() public alertText: string = '';
  @Input() public alertTitleText: string = 'alert.default_title';

  constructor(private readonly transloco: TranslocoService) {}

  private readonly textClassMap = {
    [AlertType.SUCCESS]: 'text-green-800',
    [AlertType.WARNING]: 'text-yellow-800',
    [AlertType.DANGER]: 'text-red-800',
    [AlertType.INFO]: 'text-blue-800',
  };

  private readonly bgClassMap = {
    [AlertType.SUCCESS]: 'bg-green-50',
    [AlertType.WARNING]: 'bg-yellow-50',
    [AlertType.DANGER]: 'bg-red-50',
    [AlertType.INFO]: 'bg-blue-50',
  };

  public getTitleClass(): string {
    return this.textClassMap[this.type];
  }

  public getAlertClass(): string {
    return this.textClassMap[this.type];
  }

  public getBgClass(): string {
    return this.bgClassMap[this.type];
  }
}
