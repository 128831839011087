import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StatusEventsService {
  private requestOnAir$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public setHttpStatus(isOnAir: boolean): void {
    this.requestOnAir$.next(isOnAir);
  }

  public getHttpStatus(): Observable<boolean> {
    return this.requestOnAir$.asObservable();
  }
}
