import { AsyncPipe, DecimalPipe, NgIf } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/compat/storage';
import { FormsModule } from '@angular/forms';
import { TranslocoPipe, TranslocoService } from '@jsverse/transloco';
import { PDFDocument } from 'pdf-lib';
import { combineLatest, forkJoin, map, Observable, takeLast } from 'rxjs';
import { FileService } from '../../services/file.service';
import { ToastService } from '../../services/toast.service';
import { SpinnerComponent } from '../spinner/spinner.component';
import { v4 as uuid } from 'uuid';

export interface FileUploadTask {
  task: AngularFireUploadTask;
  percentage: Observable<number | undefined>;
  file: File;
  filePath: string;
}

@Component({
  selector: 'stiilt-upload',
  standalone: true,
  imports: [TranslocoPipe, FormsModule, AsyncPipe, DecimalPipe, NgIf, SpinnerComponent],
  templateUrl: './upload.component.html',
  styles: ``,
})
export class UploadComponent implements OnChanges {
  protected readonly UploadType = UploadType;

  @ViewChild('fileInput') fileInput!: ElementRef;
  @Input() public multiple = false;
  @Input() public isFPSModeEnabled = false;
  @Input() public uploadType: UploadType = UploadType.CSV;
  @Input() public hintText: string = '';
  @Output() public filesAdded: EventEmitter<{ numberOfFiles: number; files: File[] }> = new EventEmitter();
  @Output() public filesUploaded: EventEmitter<string[]> = new EventEmitter();

  public selectedFiles: File[] = [];
  public splittedFiles: File[] = [];

  public isDragged = false;

  public isLoading: boolean = false;
  public taskList: FileUploadTask[] = [];
  public isSplitting: boolean = false;
  public combinedPercentage!: Observable<string>;

  constructor(
    private readonly fileService: FileService,
    private readonly storage: AngularFireStorage,
    private readonly toastService: ToastService,
    private readonly translocoService: TranslocoService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.selectedFiles = [];
    this.splittedFiles = [];
    this.filesAdded.emit({
      files: [],
      numberOfFiles: 0,
    });
  }

  public enableDragged(ev: DragEvent) {
    this.isDragged = true;
    ev.preventDefault();
  }

  public disableDragged(ev: DragEvent) {
    this.isDragged = false;
    ev.preventDefault();
  }

  public getAcceptTypes() {
    return this.uploadType === UploadType.PDF ? 'application/pdf' : ['text/csv', 'text/tab-separated-values'];
  }

  public getKoSize(byteSize: number): number {
    let koSize = byteSize / 1024;
    return Number(koSize.toFixed(0));
  }

  public getMoSize(byteSize: number): number {
    let mbSize = byteSize / (1024 * 1024);
    return Number(mbSize.toFixed(0));
  }

  public getMoSizeIfKoIsTooBig(byteSize: number): string {
    return this.getKoSize(byteSize) > 1024 ? `${this.getMoSize(byteSize)} Mo` : `${this.getKoSize(byteSize)} Ko`;
  }

  public uploadFiles(): void {
    const filesToUpload = this.isFPSModeEnabled ? this.splittedFiles : this.selectedFiles;
    for (let file of filesToUpload) {
      const result = this.createUploadTask(file);
      this.taskList.push({
        task: result.task,
        percentage: result.task.percentageChanges(),
        file,
        filePath: result.path,
      });
    }
    this.isLoading = true;

    const progressObservables = this.taskList.map((t) => t.percentage);
    this.combinedPercentage = combineLatest(progressObservables).pipe(
      map((progresses) => {
        const totalProgress = progresses.reduce((acc, progress) => acc! + (progress || 0), 0);
        return (totalProgress! / progresses.length).toFixed(0);
      }),
    );

    const tasks = this.taskList.map((t) => t.task.snapshotChanges().pipe(takeLast(1)));
    combineLatest(tasks).subscribe((files) => {
      this.filesUploaded.emit(this.taskList.map((a) => a.filePath));
    });
  }

  private createUploadTask(file: File): { task: AngularFireUploadTask; path: string } {
    const filePath = this.fileService.constructFilePath(FileService.getFileExtension(file.name));
    return { task: this.storage.upload(filePath, file), path: filePath };
  }

  public dropHandler(ev: DragEvent): void {
    if (!this.isLoading) ev.preventDefault();

    const files = ev.dataTransfer!.files;
    const validFiles: File[] = [];
    const invalidFiles: File[] = [];

    for (let i = 0; i < files.length; i++) {
      const file = files.item(i)!;
      if (this.getAcceptTypes().includes(file.type)) {
        validFiles.push(file);
      } else {
        invalidFiles.push(file);
      }
    }

    if (!this.multiple && validFiles.length > 0) {
      this.selectedFiles = [validFiles[0]];
    } else {
      this.selectedFiles = validFiles;
    }

    if (invalidFiles.length > 0) {
      this.toastService.warning(
        this.translocoService.translate('upload.error.invalid_file_type', { value: invalidFiles.length }),
      );
    }

    if (this.isFPSModeEnabled && this.uploadType === UploadType.PDF) {
      this.handleSplitFiles(this.selectedFiles);
    } else {
      this.filesAdded.emit({
        files: this.selectedFiles,
        numberOfFiles: this.selectedFiles.length,
      });
    }
  }

  public dragOverHandler(ev: DragEvent): void {
    if (!this.isLoading) ev.preventDefault();
  }

  public handleFileInput(event: Event): void {
    const files = Array.from((event?.target as HTMLInputElement).files!);
    //In order to reset the input file, in order to allow selecting the same file twice
    (event?.target as HTMLInputElement).files = null;
    (event?.target as HTMLInputElement).value = '';
    this.selectedFiles = files;
    if (this.isFPSModeEnabled && this.uploadType === UploadType.PDF) {
      this.handleSplitFiles(this.selectedFiles);
    } else {
      this.filesAdded.emit({
        files: this.selectedFiles,
        numberOfFiles: this.selectedFiles.length,
      });
    }
  }

  private handleSplitFiles(files: File[]): void {
    let totalPages: number = 0;
    const t0 = performance.now();

    const allPages = files.map((file) => this.splitFile(file));

    forkJoin(allPages).subscribe((pages) => {
      totalPages += pages.reduce((acc, val) => acc + val.length, 0);
      this.splittedFiles = pages.flat();

      const t1 = performance.now();
      console.log('Splitting took ' + (t1 - t0) + ' milliseconds.');

      this.filesAdded.emit({
        files: this.splittedFiles,
        numberOfFiles: totalPages,
      });
    });
  }

  private splitFile(file: File): Observable<File[]> {
    return new Observable<File[]>((observer) => {
      this.isSplitting = true;
      const pageFiles: File[] = [];
      const fileReader = new FileReader();

      fileReader.readAsArrayBuffer(file);
      fileReader.onerror = () => {
        this.isSplitting = false;
        observer.error(new Error('Failed to read file'));
      };
      fileReader.onload = async () => {
        try {
          const existingPdfBytes = new Uint8Array(fileReader.result as ArrayBuffer);
          const pdfDoc = await PDFDocument.load(existingPdfBytes, { ignoreEncryption: true });
          const numberOfPages = pdfDoc.getPageCount();

          for (let i = 0; i < numberOfPages; i++) {
            const subDocument = await PDFDocument.create();
            const [copiedPage] = await subDocument.copyPages(pdfDoc, [i]);
            subDocument.addPage(copiedPage);
            const pdfBytes = await subDocument.save();
            const pageBlob = new Blob([pdfBytes], { type: 'application/pdf' });
            pageFiles.push(new File([pageBlob], `fps-${uuid()}.pdf`, { type: pageBlob.type }));
          }
          this.isSplitting = false;
          observer.next(pageFiles);
          observer.complete();
        } catch (error) {
          this.isSplitting = false;
          observer.error(error);
        }
      };
    });
  }

  public openFileSystem(): void {
    this.fileInput!.nativeElement.click();
  }

  handleCancel(event: Event) {
    this.selectedFiles = [];
    this.splittedFiles = [];
    this.filesAdded.emit({
      files: [],
      numberOfFiles: 0,
    });
  }
}

export enum UploadType {
  PDF = 'pdf',
  CSV = 'csv',
}
