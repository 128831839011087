import { Component, Input } from '@angular/core';

@Component({
  selector: 'stiilt-title',
  standalone: true,
  imports: [],
  templateUrl: './title.component.html',
})
export class TitleComponent {
  @Input() public additionalClasses: string = '';
  @Input({ required: true }) public title: string = '';
}
