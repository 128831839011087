<div class="flex-col-center h-full w-full">
  <stiilt-title [title]="'upload_parking_ticket.title' | transloco"></stiilt-title>
  <div class="flex flex-col w-full max-w-6xl gap-5">
    <div class="flex-row-center">
      <p class="pt-0 sm:pt-2 text-center">
        {{ 'upload_parking_ticket.description' | transloco }}
      </p>
    </div>
    <div class="flex flex-row gap-5 h-28">
      <stiilt-card containerStyleClass="px-4 py-5 sm:p-3 h-full" styleClass="h-full text-center" class="w-1/2">
        <div class="flex-row-center gap-3 h-full">
          <i class="fa-solid fa-expand text-2xl"></i>
          <div class="flex-col-center h-full items-center">
            <p>{{ 'upload_parking_ticket.advice_1' | transloco }}</p>
          </div>
        </div>
      </stiilt-card>
      <stiilt-card containerStyleClass="px-4 py-5 sm:p-3 h-full" styleClass="h-full text-center" class="w-1/2">
        <div class="flex-row-center gap-3 h-full">
          <i class="fa-solid fa-print text-2xl"></i>
          <div class="flex-col-center h-full justify-center">
            <p>{{ 'upload_parking_ticket.advice_2' | transloco }}</p>
          </div>
        </div>
      </stiilt-card>
    </div>
    <div class="flex-c xl:flex-row w-full gap-5">
      <div class="flex justify-center items-center w-full">
        <div class="flex-c gap-5 w-full">
          <stiilt-upload
            [hintText]="'upload.only_pdfs' | transloco"
            (filesAdded)="handleFilesAdded($event)" [uploadType]="UploadType.PDF"
            [multiple]="isFPSModeEnabled"
            [isFPSModeEnabled]="isFPSModeEnabled"
            (filesUploaded)="handleFilesUploaded($event)"></stiilt-upload>

          <stiilt-alert *ngIf="canContinue" [type]="AlertType.SUCCESS"
                        [alertTitleText]="'upload_parking_ticket.success_title' | transloco"
                        [alertText]="(isFPSModeEnabled ? 'upload_parking_ticket.success_text_several_files' : 'upload_parking_ticket.success_text') | transloco: {nbOfParkingTickets: numberOfParkingTickets}"/>
        </div>
      </div>
    </div>

    <div class="flex w-full max-w-6xl" [class]="{
    'justify-between': showBackButton,
    'justify-end': !showBackButton
    }">
      <p-button *ngIf="showBackButton" [label]="'static_fleet.previous' | transloco" (onClick)="previous()"/>
      <p-button [loading]="fileUpload?.isLoading! || fileUpload?.isSplitting!" [disabled]="fileUpload?.isLoading! || !canContinue"
                (onClick)="uploadFiles()" [label]="'static_fleet.upload' | transloco"/>
    </div>
  </div>
</div>
