import { Component, Input } from '@angular/core';

@Component({
  selector: 'stiilt-card',
  standalone: true,
  imports: [],
  templateUrl: './card.component.html',
  styles: `
    .feature-card {
      color: rgba(7, 14, 39, 0.6);
      background-color: rgba(0, 0, 0, 0);
      border: 1px solid #e2e7fa;
      border-radius: 16px;
      text-decoration: none;
      transition: all 0.3s;
    }

    .feature-card:hover {
      background-color: #fff;
      border-color: #fff;
      box-shadow: 37px 37px 96px rgba(7, 14, 39, 0.08);
    }
  `,
})
export class CardComponent {
  @Input() public styleClass: string = '';
  @Input() public containerStyleClass: string = 'px-4 py-5 sm:p-6 h-full';
}
