import { Injectable } from '@angular/core';
import { EnvService } from '@core/services/env.service';
import { Observable } from 'rxjs';
import { isString, tail } from 'lodash';
import { v4 as uuid } from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  public uploadPercent$!: Observable<number | undefined>;

  constructor(private readonly env: EnvService) {}

  public static getFileName(filePath: string): string | undefined {
    return isString(filePath) ? tail(filePath.split('/')).join('/') : undefined;
  }

  public static getFileExtension(filename: string): string | undefined {
    if (isString(filename)) {
      const extension = filename.split('.').slice(-1)[0];
      return extension ? extension.toLowerCase() : undefined;
    }
    return undefined;
  }

  public constructFilePath(fileExtension: string | undefined): string {
    return `fines/${uuid()}.${fileExtension}`;
  }

  public getBucketPath(filePath: string, includeKey: boolean = true): string {
    return `${this.env.urls.firebaseBucketUrl}${this.env.firebaseCredentials.storageBucket}/o/${encodeURIComponent(
      filePath,
    )}?alt=media${includeKey ? `&key=${this.env.firebaseCredentials.storageKey}` : ''}`;
  }
}
