import { formatDate } from '@angular/common';
import moment from 'moment-timezone';
import 'moment/locale/fr';
import 'moment/locale/en-gb';
import Diff = moment.unitOfTime.Diff;

export class DateUtils {
  public static readonly dateOnlyIsoFormat = 'yyyy-MM-dd';

  public static readonly hours: Diff = 'hours';

  public static readonly minutes: Diff = 'minutes';

  public static readonly seconds: Diff = 'seconds';
  public static readonly locale = 'en-US';

  public static readonly timezoneParis = 'Europe/Paris';

  public static readonly dateTimeFormatUser = 'DD/MM/YYYY HH:mm';

  public static readonly localeFrench = 'fr';

  public static toApiDate(date: Date): string | null {
    return date ? formatDate(date, DateUtils.dateOnlyIsoFormat, DateUtils.locale) : null;
  }

  public static formatDateToCustomFormatAndTimezone(
    date: Date | string,
    format: string,
    tz: string,
    locale?: string,
  ): string {
    if (!locale) {
      return moment(date).tz(tz).format(format);
    }
    const momentConvertedToTz = moment(date).tz(tz);

    return momentConvertedToTz.locale(locale).format(format);
  }
}
