import { booleanAttribute, Component, Input, numberAttribute } from '@angular/core';
import { NgIf } from '@angular/common';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

@Component({
  selector: 'stiilt-spinner',
  standalone: true,
  imports: [NgIf, ProgressSpinnerModule],
  templateUrl: './spinner.component.html',
  styles: `
    .circular-progress {
      position: relative;
      height: 50px;
      width: 50px;
      border-radius: 50%;
      background: conic-gradient(#0059f7 3.6deg, #ededed 0deg);
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .circular-progress::before {
      content: '';
      position: absolute;
      height: 30px;
      width: 30px;
      border-radius: 50%;
      background-color: #fff;
    }

    .progress-value {
      position: relative;
      font-size: 12px;
      font-weight: 500;
      color: #0059f7;
    }
  `,
})
export class SpinnerComponent {
  @Input({ transform: booleanAttribute }) public indeterminate: boolean = true;
  @Input({ transform: numberAttribute }) public progressValue!: number;
  @Input() public enableText: boolean = false;

  getStyle() {
    return `conic-gradient(#0059f7 ${this.progressValue * 3.6}deg, #ededed 0deg)`;
  }
}
