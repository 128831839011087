import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class EnvService {
  public static readonly PREPROD_CUSTOMER_APP_NAME = 'fpsmanager-preprod';
  public firebaseCredentials: {
    apiKey?: string;
    authDomain?: string;
    projectId?: string;
    storageBucket?: string;
    messagingSenderId?: string;
    appId?: string;
    measurementId?: string;
    storageKey?: string;
  } = {};

  public urls: {
    stripeUrl?: string;
    firebaseBucketUrl?: string;
  } = {};

  public stripePublishableKey?: string = 'stripePublishableKey';

  public debugMode = false;

  public version = 'debug';

  public production = false;

  public baseUrl = '/api';

  public isProduction(): boolean {
    return this.firebaseCredentials.projectId !== EnvService.PREPROD_CUSTOMER_APP_NAME;
  }
}
