import { NgIf } from '@angular/common';
import { booleanAttribute, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslocoPipe } from '@jsverse/transloco';
import { ProcessingBatch } from '@shared/models/parking-ticket/processing-batch.model';
import { ProcessingBatchHttpService } from '@shared/services/processing-batch-http.service';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { AlertComponent, AlertType } from '../../alert/alert.component';
import { CardComponent } from '../../card/card.component';
import { TitleComponent } from '../../stiilt-title/title.component';
import { UploadComponent, UploadType } from '../../upload/upload.component';
import { ProcessingBatchType } from './types/processing-batch.type';

@Component({
  selector: 'stiilt-upload-parking-tickets',
  standalone: true,
  imports: [
    AlertComponent,
    ButtonModule,
    TitleComponent,
    TranslocoPipe,
    UploadComponent,
    NgIf,
    CardComponent,
    UploadComponent,
    CheckboxModule,
    FormsModule,
  ],
  templateUrl: './upload-parking-tickets.component.html',
})
export class UploadParkingTicketsComponent {
  @ViewChild(UploadComponent) public fileUpload: UploadComponent | undefined;

  @Output('nextStepClicked') private nextStepEmitter: EventEmitter<ProcessingBatch> =
    new EventEmitter<ProcessingBatch>();
  @Output('backStepClicked') private backStepEmitter = new EventEmitter<void>();
  @Input({ required: true }) public uploadType!: ProcessingBatchType;
  @Input({ transform: booleanAttribute }) showBackButton: boolean = false;

  protected readonly UploadType = UploadType;
  protected readonly AlertType = AlertType;

  public selectedFiles: File[] | undefined;
  public canContinue: boolean = false;
  public isFPSModeEnabled = true;
  public numberOfParkingTickets!: number;

  constructor(private readonly parkingTicketsHttpService: ProcessingBatchHttpService) {}

  public handleFilesAdded(event: { files: File[]; numberOfFiles: number }): void {
    if (event.numberOfFiles > 0) {
      this.canContinue = true;
      this.selectedFiles = event.files;
      this.numberOfParkingTickets = event.numberOfFiles;
    } else {
      this.canContinue = false;
      this.selectedFiles = undefined;
    }
  }

  public uploadFiles(): void {
    this.fileUpload?.uploadFiles();
  }

  public handleFilesUploaded(filePaths: string[]): void {
    this.parkingTicketsHttpService.createParkingTickets(filePaths, this.uploadType).subscribe((processingBatch) => {
      this.nextStepEmitter.emit(processingBatch);
    });
  }

  public previous(): void {
    this.backStepEmitter.emit();
  }
}
